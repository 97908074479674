<template>
  <b-form-file
    v-model="context.model"
    placeholder="Choose a file or drop it here..."
    drop-placeholder="Drop file here..."
  ></b-form-file>
</template>

<script>
export default {
  components: {},
  props: {
    context: {
      type: Object,
      required: true,
      default:null,
    },
  },
  methods: {
    onClose: function () {
      this.context.blurHandler();
    },
  },
};
</script>
