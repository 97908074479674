export const state = {
  slectedDriver: null,
};

export const mutations = {
  SELECT_DRIVER(state, driver) {
    state.slectedDriver = driver;
  },
};

export const getters = {
  currentDriver(state) {
    return state.slectedDriver;
  },
 
};


export const actions = {
  selectDriver({ commit }, driver) {
    commit("SELECT_DRIVER", driver);
  },
};
