<template>
  <div id="app">
    <GmapMap
      :center="center"
      :zoom="10"
      map-style-id="roadmap"
      :options="mapOptions"
      style="width: 100%; height: 250px"
      ref="mapRef"
      @click="handleMapClick"
    >
      <GmapMarker
        :position="marker.position"
        :clickable="true"
        :draggable="true"
        @drag="handleMarkerDrag"
        @click="panToMarker"
      />
    </GmapMap>
  </div>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      mapOptions: {
        disableDefaultUI: true,
      },
      marker: {
        position: { lat: 24.4860964, lng: 54.357658 },
      },
      center: {
        lat: 24.4860964,
        lng: 54.357658,
      },
    };
  },
  mounted() {
    this.geolocate();
  },
  methods: {
    //detects location from browser
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.marker.position = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.panToMarker();
      });
      this.positionChanged();
    },

    //sets the position of marker when dragged
    handleMarkerDrag(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
    },

    //Moves the map view port to marker
    panToMarker() {
      this.$refs.mapRef.panTo(this.marker.position);
      this.positionChanged();
    },

    //Moves the marker to click position on the map
    handleMapClick(e) {
      this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
      this.positionChanged();
    },
    positionChanged() {
      this.context.model = this.marker.position;
    },
  },
};
</script>

<style></style>
