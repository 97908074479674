<template>
  <div class="add-tags-input-warpper">
    <b-form-tags class="add-tags-input" input-id="tags-basic" v-model="context.model"></b-form-tags>
  </div>

</template>

<script>
export default {
  components: {
  },
  props: {
    context: {
      type: Object,
      required: true
    },
  },
  methods: {
    onClose: function () {
      this.context.blurHandler();
    }
  }
}
</script>

<style>
.add-tags-input {
  background-color: #f1f1f1;
  border: none;
}

.add-tags-input-warpper .focus {
  border: #000 !important;
  background-color: #f1f1f1 !important;

}
</style>