<template>
  <div>
    <multiselect
              :multiple="true"
              label="name"
              v-model="context.model"
              :max="
                40
              "
              track-by="value"
              :maxHeight="400"
              :searchable="true"
              placeholder="Search driver"
              :options="context.options"
            ></multiselect>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";

  export default {
    components: {
      Multiselect
    },
      watch: {
          value: function (val) {
      
            this.context.model = this.options.filter((item) => val.includes(item.label))
    }
      },
    data() {
      return {
        options: this.context.options,
        search: '',
        value: []
      }
    },
    computed: {
      criteria() {
        // Compute the search criteria
        return this.search.trim().toLowerCase()
      },
      availableOptions() {
          console.log(this.context)
        const criteria = this.criteria
        // Filter out already selected options
        const options = this.options.filter(opt => this.value.indexOf(opt.label) === -1)
        if (criteria) {
          // Show only options that match criteria
          return options.filter(opt => opt.label.toLowerCase().indexOf(criteria) > -1);
        }
        // Show all options available
        return options
      },
      searchDesc() {
        if (this.criteria && this.availableOptions.length === 0) {
          return `There are no ${this.context.name} matching your search criteria`
        }
        return ''
      }
    },
    methods: {
      onOptionClick({ option, addTag }) {
          console.log(option);
        addTag(option.label)
        this.search = ''
      }
    },
    props: {
    context: {
      type: Object,
      required: true
    },
  },
  }
</script>
<style>
label::first-letter {
    text-transform:capitalize;
}
.add-tags-input{
    background-color: #f1f1f1;
    border: none;
}
.add-tags-input-warpper .focus{
    border: #000 !important;
    background-color: #f1f1f1 !important;

}
</style>